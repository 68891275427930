<!--
  Component to visualize an item that has been ordered, along with its additional info:
    - Serialnumber if available
    - Ordernumber
    - Order-status
    - Stock-status

  Can be used in two ways. Either to represent an orderline that may have quantity>1, or as a single item
-->
<template>
  <div
    :class="{
      'ordered-item':true,
      'ordered-item--id-selectable': idSelectable,
      'ordered-item--assigned': item.reference
    }"
  >
    <header>
      <div
        class="ordered-item__image"
        :class="{
          'ordered-item__image--interactive': interactive && !imageFailed,
        }"
        @click="interactive && !imageFailed? openLightboxImage($event): null"
      >
        <img
          v-if="!interactive || !imageFailed || !imageReplacable"
          :src="getImageFor(item.item).thumb"
          :alt="item.item.externalid"
          @error="imgFailed"
        >
        <image-input
          v-else
          v-model="item.item.image"
          class="photo-input"
          :fit="'cover'"
          :loading="imageLoading"
          :default="getImageFor(item).thumb"
          @input="onImageInput($event)"
          @error="imgFailed"
        />
      </div>
      <div class="ordered-item__content">
        <span
          v-if="item.quantity"
          class="ordered-item__quantity"
        >
          <span class="ordered-item__quantity-label">
            x
          </span>
          <span class="ordered-item__available-quantity">
            {{ item.quantity.available }}
          </span>
          <span
            v-if="item.quantity && item.quantity.total !== 1"
            class="ordered-item__quantity-divider"
          >
            /
          </span>
          <span
            v-if="item.quantity && item.quantity.total !== 1"
            class="ordered-item__total-quantity"
          >
            {{ item.quantity.total }}
          </span>
        </span>

        <small
          v-if="item.order.status"
          class="ordered-item__timing-status"
          style="color: #8d99af;"
        >
          <template
            v-if="!(item.order.status.details.find(x => x.status==='shipped')
              && item.order.status.details.find(x => x.status==='shipped').date !== null)
              && (showRequestedDate(item.order,
                                    item.order.requesteddeliverydate || item.order.plannedshippingdate
              ) || item.order.promiseddeliverydate)"
          >
            <span v-if="item.order.promiseddeliverydate">
              <strong>ETD</strong>: {{ customFormatter(item.order.promiseddeliverydate) }}
            </span>
            <span v-else-if="item.order.requesteddeliverydate">
              <strong>RTD</strong>: {{ customFormatter(item.order.requesteddeliverydate) }}
            </span>
          </template>
        </small>
        <strong class="ordered-item__description">
          {{ item.item.description }}
        </strong>
        <div class="ordered-item__tags">
          <template v-for="i of items">
            <span
              v-if="i.serial || i.index"
              :key="i.serial || i.index"
              class="ordered-item__id-tag ordered-item__tag"
              :data-item-id="i.id"
            >
              <i
                class="uil uil-pricetag-alt"
                style="margin: -0.5em;"
              /> {{ i.serial || `#${i.index}` }}

              <span
                v-if="i.stock"
                class="ordered-item__tag__stock"
              >
                {{ i.stock.status === 'available'? $t('account.account.myCustomers.inStock') : $t('account.account.myStock.statuses.' + i.stock.status) }}
              </span>
            </span>
          </template>
          <span
            v-if="item.item.frame_size"
            class="ordered-item__tag"
            style="padding: 0.5rem 1.2rem 0.5rem 1.2rem;"
          >{{ item.item.frame_size }}</span>

          <span
            v-if="item.item.configurationid"
            class="ordered-item__tag"
          >
            <i
              class="uil uil-wrench"
              style="margin: -0.5em;"
            />
            {{ item.item.configurationid }}
          </span>
        </div>

        <div class="ordered-item__slotted">
          <slot />
        </div>
        <div class="ordered-item__orderid">
          <label v-if="item.order.externalid"> {{ item.order.externalid }}</label>
          <label v-else-if="item.order.orderid"> #{{ item.order.orderid }}</label>
        </div>
        <div class="ordered-item__orderinfo">
          <div class="ordered-item__orderdate">
            <span v-if="item.order.shipped"> {{ formatDate(item.order.shipped) }}</span>
          </div>
          <small
            v-if="item.order.status && item.order.status.details"
            class="ordered-item__orderstatus"
          ><i
            v-if="(item.order.status.details.find(x => x.status==='shipped')
              && item.order.status.details.find(x => x.status==='shipped').date !== null)"
            v-b-tooltip.hover
            class="uil uil-truck m-r-1"
            :title="'Shipped '+customFormatter(item.order.status.details.find(x => x.status==='shipped').date)"
          />{{ $t('account.account.myOrders.pendingStatus.'+ nextOrderStatus(item.order.status)) }}</small>
        </div>
      </div>
    </header>

    <ItemImageViewer
      ref="lightbox"
      :item="item"
      :editable="imageReplacable"
      @imageselected="onLightboxImageSelected($event)"
    />
  </div>
</template>
<script>
import { formatDate } from '@/utils/dateUtils';
import { getImageFor } from '@/utils/itemUtils';
import ImageInput from '@/elements/ImageInput.vue';
import { nextOrderStatus } from '@/utils/orderUtils';
import { mapState } from 'vuex';
import ItemImageViewer from './itemImageViewer.vue';

export default {
  name: 'OrderedItem',
  components: {
    ItemImageViewer,
    ImageInput,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    interactive: {
      // This will trigger lightbox-interaction upon clicking the image
      type: Boolean,
      default: false,
    },
    idSelectable: {
      // Allow clicking of individual id-tags
      type: Boolean,
      default: false,
    },
    canReplaceImage: {
      type: [Function, Boolean],
      default: null,
    },
  },
  data() {
    return {
      lightboxImage: [],
      imageFailed: false,
      imageLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    customerId() {
      return this.$store.state.customer.selectedCustomer.id;
    },
    imageReplacable() {
      const { canReplaceImage } = this;
      return canReplaceImage && (
        typeof (canReplaceImage) === 'function'
          ? canReplaceImage(this.item, this.imageFailed)
          : canReplaceImage === true
      );
    },
    items() {
      // An item might be single or multiple. This unifies both
      // TODO: group em by stock-status?
      if (!this.item) return [];
      return this.item.items ? this.item.items : [{
        serial: this.item.serial,
        ...(this.item.reference || {}),
      }];
    },
  },
  mounted() {
  },
  methods: {
    formatDate,
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
        this.imageFailed = true;
      }
    },
    openLightboxImage(event) {
      if (event && !event.target.failed) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();
      }
      this.$refs.lightbox.show();
    },
    onImageInput(fileData) {
      // Emitted from image-input component (when no image could be resolved at all)
      this.$emit('imageinput', { item: this.item, file: fileData });
    },
    onLightboxImageSelected({ files }) {
      // Emitted from stock-lightbox (when some image was already resolved and user wants to replace it)
      if (files && files.length) {
        this.$emit('imageinput', { item: this.item, file: { file: files[0] } });
      }
    },
    customFormatter(date) {
      return (date) ? formatDate(date) : '-';
    },
    nextOrderStatus,
    getImageFor,
  },
};
</script>

<style lang="scss" scoped>

</style>
