/* eslint-disable no-nested-ternary */
<template>
  <BcfDialog
    v-dialog-config="{
      overlay,
      scroll: 'window', // Fix reflow with searchInput
      beforeShow: beforeShow, // Load SearchInput result
      beforeClose: beforeClose // Prevent unsaved changes from getting lost
    }"
    :overlay="overlay"
    :title="$t(`account.account.myCustomers.detail.${isNewRelation ? 'titleNew' : 'title'}`) "
    wide
  >
    <form
      style="display: contents"
      @submit.prevent="save"
    >
      <RelationInfo
        v-model="model"
        class="section"
      />

      <Message
        v-if="errorMessage"
        type="error"
        :icon="true"
        :content="$t(`api.${errorMessage}`)"
      />
    </form>
    <template v-slot:footer>
      <button
        class="button button--secondary"
        @click.stop="close()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        class="button button--primary"
        type="submit"
        :disabled="!isValid || (!hasChanges && !selected)"
        @click.stop.prevent="save"
      >
        <loading-overlay :loading="isLoading('save')">
          {{ $t(`account.account.myCustomers.${(isNewRelation && !selected) ? 'create' : 'save'}`) }}
        </loading-overlay>
      </button>
    </template>
  </BcfDialog>
</template>
<script>

// import {
//   email,
// } from 'vuelidate/lib/validators';
import Message from '@/components/Message.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import { mapState } from 'vuex';
import translations from '@/translations';
import {
  AddNewCustomerRelation, UpdateCustomerRelation,
} from '@/api/api';
import dialogs from '@/utils/dialogs';
import { isEqual } from '@/utils/object';
import { isValidRelation } from '@/views/account/account/mycustomers/utils';
import RelationInfo from './relationInfo.vue';
import RelationDuplicateDialog from './relationDuplicateDialog.vue';

// Elements
export default {
  components: {
    // loader,
    RelationInfo,
    loadingOverlay,
    Message,
    BcfDialog,
  },
  props: {
    overlay: {
      // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: 'create',
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: {},
      selected: null,
      model: {},
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
    isNewRelation() {
      return Number.isNaN(Number.parseInt((this.model || this.value)?.id, 10));
    },
    isValid() {
      return isValidRelation(this.model);
    },
    rid() {
      const v = (this.model || this.value);
      return v && v.id;
    },
    lang() {
      // Need this for the datepicker!
      return translations[this.$store.state.language.current].Datepicker;
    },
    hasChanges() {
      return this.isNewRelation || !isEqual(this.model, this.valueToModel(false, this.selected || this.value));
    },
    hasItemsWithSerial() {
      return this.model.items.find((t) => t.serial);
    },
  },
  validations() {
    return {
      model: {
        // No real validations yet
      },
    };
  },
  watch: {
    value() {
      this.selected = null;
      this.valueToModel();
    },
  },
  mounted() {
    this.valueToModel();
  },
  methods: {
    beforeShow() {
    },
    async beforeClose(result, source) {
      if (source === 'dismiss') {
        if (this.hasChanges) {
          const confirmed = await dialogs.show({
            title: this.$t('general.unsavedChanges.title'),
            content: this.$t('general.unsavedChanges.content'),
            type: 'cancelYes',
          });
          if (confirmed) {
            return true;
          }
          return false;
        }
      }
      return undefined;
    },
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    markLoading(key = 'save', value = true) {
      this.loading = {
        ...this.loading,
        [key]: value,
      };
    },
    markLoaded(key = 'save') {
      this.markLoading(key, false);
    },
    isLoading(key = 'save') {
      return !!this.loading[key];
    },
    valueToModel(apply = true, setValue = undefined) {
      const newValue = {
        ...(setValue || this.value || {}),
      };
      if (apply) {
        this.model = newValue;
      }
      return newValue;
    },
    async save() {
      const { model } = this;
      const request = {
        ...model,
      };
      delete request.items;
      const { rid } = this;
      const cid = this.customerId;
      let result = null;
      try {
        this.markLoading();
        this.errorMessage = null;
        if (this.selected && !this.hasChanges) {
          result = this.model;
        } else if (this.isNewRelation) {
          try {
            result = await AddNewCustomerRelation(this.customer.selectedCustomerId, request);
            this.relation = result;
          } catch (e) {
            this.selected = null;
            if (e.body && e.status === 409 && e.body.err && e.body.err?.name === 'potentialDuplicate') {
              const duplicates = e.body.err.data;
              const duplicateOutcome = await dialogs.show({
                component: RelationDuplicateDialog,
                props: {
                  duplicates,
                  value: this.model,
                },
              });
              if (duplicateOutcome === true) {
                result = await AddNewCustomerRelation(this.customer.selectedCustomerId, { ...request, duplicateConfirm: true });
              } else if (!duplicateOutcome) {
                // NO-OP, just cancel and let user keep editing. Keep result empty
              } else {
                const mergedModel = {
                  ...duplicateOutcome,
                };
                for (const key in this.model) {
                  if (this.model[key]) {
                    mergedModel[key] = this.model[key];
                  }
                }
                this.selected = duplicateOutcome;
                this.model = mergedModel;
                if (!this.hasChanges) {
                  // Merging did not change anything
                  result = this.model;
                } else {
                  // User has to save again, and it will now save to specified relation. Thus leave result empty but update model and keep track of what was selected
                }
              }
            } else {
              throw e;
            }
          }
        } else {
          result = await UpdateCustomerRelation({
            cid, rid,
          }, request);
          this.origRelation = result;
          this.relation = { ...result };
        }
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.markLoaded();
      }

      if (!this.errorMessage && result) {
        this.close(result, 'submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.assigned-item{
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  &__detail{
    flex: 1 1 auto;
  }
  &__remove{
    flex: 0 0 auto;
    align-self: center;
  }
}

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}

.relation-search{
}

.relation{
  display: grid;
  padding: .25em;
  line-height: 1.0em;

  &__email{
    grid-row: 2;
    grid-column: 1/4;
    color: $red;
    text-decoration: none;
    font-size: 90%;
  }
  &__name{
    grid-row: 1;
    grid-column: 1/3;
  }
  &__phone{
    grid-row: 1;
    grid-column: 3/5;
    text-align: right;
    font-size: 90%;
    color: #666;
  }
  &__create{
    text-align: center;

    &--subtle{
        color: #666;
    }
  }
  &__edit{
    grid-row: 2;
    grid-column: 4;
    text-align: right;
    a{
      color: $red;
      text-decoration: underline;
    }
  }
}

</style>
