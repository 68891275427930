/* eslint-disable no-nested-ternary */
<template>
  <BcfDialog
    v-dialog-config="{
      overlay,
      scroll: 'window', // Fix reflow with searchInput
      beforeShow: beforeShow, // Load SearchInput result
      beforeClose: beforeClose // Prevent unsaved changes from getting lost
    }"
    :overlay="overlay"
    :title="$t(`account.account.myCustomers.${mode === 'edit'? 'editItem' : 'assignItems'}`)"
    wide
  >
    <form
      style="display: contents"
      @submit.prevent="save"
    >
      <div
        class="section"
      >
        <label :for="`assignNew_item`">
          {{ $t('account.account.myCustomers.items') }}
        </label>

        <search-input
          v-if="mode !== 'edit' && allowEditItems"
          v-model="search.input"
          :placeholder="$t('account.account.myCustomers.searchOrSerial')"
          :items="search.items"
          :autofocus="relation && autofocusSearch"
          :loading="!!search.loading"
          @search="searchEquipment($event)"
          @selected="addNewItem"
        >
          <template v-slot:item="{ item }">
            <OrderedItem
              :item="item"
              :interactive="false"
              :id-selectable="true"
            />
          </template>
        </search-input>

        <!-- Might want to make the item`s list scroll on itself if it were to excede page height,-->
        <div
          class="items"
        >
          <Message
            v-if="!model.items || model.items.length===0"
            type="update"
            :icon="true"
            :content="$t('account.account.myCustomers.noEquipment')"
          />
          <template v-else>
            <div
              v-for="item of model.items"
              :key="item.serial || item.id"
              class="assigned-item"
            >
              <OrderedItem
                class="assigned-item__detail"
                :item="item"
                :interactive="true"
                :can-replace-image="false"
              />

              <button
                v-if="mode !== 'edit' && allowEditItems"
                class="button--icon button assigned-item__remove"
                @click="removeNewItem(item)"
              >
                <i class="uil uil-times" />
              </button>
            </div>
          </template>
        </div>
      </div>

      <div
        v-if="!relation"
        class="section"
      >
        <div class="form-group">
          <label :for="`assignNew_relation`">
            {{ $t('account.account.myCustomers.relation') }}
          </label>

          <relation-input
            v-model="model.relation"
            :autofocus="!relation && autofocusSearch"
          />
        </div>
      </div>

      <div class="section">
        <div
          v-if="hasItemsWithSerial"
          class="form-group"
        >
          <label for="stockStatus">
            {{ $t('account.account.myCustomers.setStockStatus') }}
          </label>

          <status-select
            id="stockStatus"
            v-model="model.stockStatus"
            :statuses="['sold','reserved']"
          />
        </div>

        <div
          v-if="hasItemsWithSerial && ['available','reserved'].includes(model.stockStatus)"
          class="form-group"
        >
          <location-select
            v-if="locations.length>1"
            class="location-select"
            :value="model.stockAddress"
            @input="selectStockLocation($event)"
          />
        </div>

        <div
          v-if="model.stockStatus === 'sold' && hasItemsWithSerial"
          class="form-group"
        >
          <label>
            {{ $t('account.account.myCustomers.purchaseDate') }}
          </label>

          <datepicker
            v-model="model.purchaseDate"
            maximum-view="month"
            :language="lang"
            :format="dateFormatter"
          />
        </div>

        <div class="form-group">
          <label :for="`assignNew_note`">
            {{ $t('account.account.myOrders.notes') }}
          </label>
          <textarea
            :id="`assignNew_note`"
            v-model="model.note"
            rows="2"
            class="form-control"
            maxlength="255"
          />
        </div>

        <div class="form-group form-group--checkbox">
          <!-- v-model="model.sendEmail" -->
          <checkbox
            :value="model.relation && model.sendEmail"
            :disabled="!model.relation"
            @change="model.sendEmail = $event"
          >
            {{ $t("account.account.myCustomers.assignSendEmail") }}
          </checkbox>
        </div>
      </div>

      <div v-if="errorMessage">
        <Message
          type="error"
          :icon="true"
          :content="$t(`api.${errorMessage}`)"
        />
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="button button--secondary"
        @click.stop="close()"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        v-if="mode === 'edit'"
        class="button button--danger"
        @click.stop="unassignItem"
      >
        <loading-overlay :loading="isLoading('unassign')">
          <span>{{ $t('account.account.myCustomers.unassign') }}</span>
        </loading-overlay>
      </button>
      <button
        class="button button--primary"
        type="submit"
        :disabled="!hasChanges && !allowEmptySave"
        @click.stop.prevent="save"
      >
        <loading-overlay :loading="isLoading('save')">
          <span>{{ $t((mode === 'edit' || !model.relation) ? 'general.save' : 'account.account.myCustomers.assign') }}</span>
        </loading-overlay>
      </button>
    </template>
  </BcfDialog>
</template>
<script>

// import {
//   email,
// } from 'vuelidate/lib/validators';
import Message from '@/components/Message.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import SearchInput from '@/components/searchInput.vue';
import OrderedItem from '@/components/item/orderedItem.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import statusSelect from '@/views/account/account/mystock/statusSelect.vue';
import checkbox from '@/elements/checkbox.vue';
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex';
import translations from '@/translations';
import { formatDate } from '@/utils/dateUtils';
import { AssignToCustomerRelation, QueryAssignableItems } from '@/api/api';
import {
  assignedItemFromSelected, itemToSplitId,
  makeEquipmentSearchResult,
} from '@/views/account/account/mycustomers/utils';
import dialogs from '@/utils/dialogs';
import { isEqual } from '@/utils/object';
import LocationSelect from '@/views/account/account/mystock/locationSelect.vue';
import UnassignDialog from './unassignDialog.vue';
import CustomerInfoDialog from './relationInfoDialog.vue';
import RelationInput from './relationInput.vue';

// Elements
export default {
  components: {
    SearchInput,
    RelationInput,
    OrderedItem,
    // loader,
    loadingOverlay,
    statusSelect,
    checkbox,
    Datepicker,
    Message,
    BcfDialog,
    LocationSelect,
  },
  props: {
    overlay: {
      // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: 'assign',
    },
    value: {
      type: Object,
      /**
       * Expected input format is
       *       {
       *        items: [],
       *        stockStatus: 'sold',
       *        stockAddress: 2,
       *        sendEmail: true,
       *        purchaseDate: new Date(),
       *       }
       */
      default: () => null,
    },
    relation: {
      // Mainly need this to know the relationId (could've just passed that in instead)
      type: Object,
      default: () => null,
    },
    autofocusSearch: {
      type: Boolean,
      default: false,
    },
    allowEditItems: {
      type: Boolean,
      default: true,
    },
    allowEmptySave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
      },
      search: {
        input: '', // Current search value
        items: [],
        query: '', // Search value corresponding to item
        loading: false,
      },
      relSearch: {
        input: '', // Current search value
        items: [],
        query: '', // Search value corresponding to item
        loading: false,
      },
      model: {
        relation: null,
        items: [],
      },
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
    rid() {
      return this.relation && this.relation.id;
    },
    lang() {
      // Need this for the datepicker!
      return translations[this.$store.state.language.current].Datepicker;
    },
    hasChanges() {
      const { model } = this;
      const valueModel = this.valueToModel(false);
      return !isEqual({
        ...model,
        purchaseDate: JSON.stringify(model.purchaseDate),
      }, {
        ...valueModel,
        purchaseDate: JSON.stringify(valueModel.purchaseDate),
      });
    },
    hasItemsWithSerial() {
      return this.model.items.find((t) => t.serial);
    },
  },
  validations() {
    return {
      model: {
        // No real validations yet
      },
    };
  },
  watch: {
    value() {
      this.valueToModel();
    },
    relation() {
      this.valueToModel();
    },
  },
  mounted() {
    this.valueToModel();
  },
  methods: {
    beforeShow() {
      if (this.mode !== 'edit' && this.allowEditItems) {
        this.searchEquipment();
      }
    },
    async beforeClose(result, source) {
      if (source === 'dismiss') {
        if (this.hasChanges) {
          const confirmed = await dialogs.show({
            title: this.$t('general.unsavedChanges.title'),
            content: this.$t('general.unsavedChanges.content'),
            type: 'cancelYes',
          });
          if (confirmed) {
            return true;
          }
          return false;
        }
      }
      return undefined;
    },
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    markLoading(key = 'save', value = true) {
      this.loading = {
        ...this.loading,
        [key]: value,
      };
    },
    markLoaded(key = 'save') {
      this.markLoading(key, false);
    },
    isLoading(key = 'save') {
      return !!this.loading[key];
    },
    updateSearchResult(search, result) {
      if (!this.search.loading || (this.search.loading && this.search.loading.search === search)) {
        this.search = {
          ...this.search,
          ...makeEquipmentSearchResult(
            search !== undefined ? search : this.search.input,
            result !== undefined ? result : this.search.result,
            this.model.items,
          ),
        };
      }
    },
    async searchEquipment(search) {
      this.search = { ...this.search, loading: { search } };
      try {
        const searchResult = (await QueryAssignableItems(
          this.customer.selectedCustomerId,
          {
            search,
          },
        ));// .filter((x) => !this.items.find((y) => y.serial === x.serial));

        this.updateSearchResult(search, searchResult);
      } catch (err) {
        this.search = { ...this.search, loading: false };
        throw err;
      }
    },
    async addNewItem(item, origEvent) {
      const assignedItem = assignedItemFromSelected(item, origEvent);
      this.model = {
        ...this.model,
        items: [...this.model.items, assignedItem],
      };
      this.updateSearchResult(this.search.query, this.search.items, this.model.items);
    },
    async removeNewItem(item) {
      this.model = {
        ...this.model,
        items: this.model.items.filter((x) => x !== item),
      };
    },
    valueToModel(apply = true) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const newValue = {
        relation: this.relation || null,
        stockStatus: 'sold',
        stockAddress: this.locations[0]?.externalid,
        purchaseDate: today,
        sendEmail: true,
        items: [],
        ...JSON.parse(JSON.stringify(this.value || [])), // Take a copy
      };
      if (apply) {
        this.model = newValue;
      }
      return newValue;
    },
    async save() {
      try {
        const { model } = this;
        let {
          rid,
        } = this;
        const cid = this.customerId;
        if (model.relation?.id) {
          rid = model.relation?.id;
        }
        this.errorMessage = null;

        this.markLoading('save');
        await AssignToCustomerRelation({
          cid,
          rid,
        }, {
          ...model,
          items: model.items.map(itemToSplitId),
        });

        this.close(this.model, 'submit');
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.markLoaded('save');
      }
    },
    async unassignItem() {
      // try {
      const { model, relation } = this;

      const result = await dialogs.show({
        component: UnassignDialog,
        props: {
          relation: relation || model.relation,
          value: {
            items: model.items,
          },
        },
      });
      if (result) {
        this.close(result, 'submit');
      }
    },
    validationState(field, ol) {
      const indexOf = ol ? this.model.findIndex((m) => m.key === ol.key) : 0;
      const olState = this.$v.model.$each[indexOf];
      const state = olState && olState[field];
      // eslint-disable-next-line no-nested-ternary
      return (state && (state.$error || state.$invalid))
        ? false // Invalid
        : !state || ([null, undefined, ''].includes(state.$model))
          ? null // Empty or unrecognized
          : true; // Valid
    },
    dateFormatter: formatDate,
    blockAutocomplete: () => -1, // The correct value would be "off", but that does not work for Chrome
    async editRelation() {
      // try {
      const { model } = this;

      const result = await dialogs.show({
        component: CustomerInfoDialog,
        props: {
          value: model.relation,
        },
      });
      if (result) {
        this.model = {
          ...this.model,
          relation: result,
        };
      }
    },
    selectStockLocation(location) {
      this.model.stockAddress = location;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.assigned-item{
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  &__detail{
    flex: 1 1 auto;
  }
  &__remove{
    flex: 0 0 auto;
    align-self: center;
  }
}

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}

.relation-search{
}

</style>
